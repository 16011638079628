<template>
    <div id="CareerModule">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="text-left my-md-1">
                        <p style="font-size: 1.3em">
                            <ul style="padding-left: 0;">
                                <li>
                                    <a href="/file/resources-factsheet/Actuary.docx" class="list-factsheet" download target="_blank">
                                        Actuary
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a> 
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Aerospace Engineering.docx" class="list-factsheet" download target="_blank">
                                        Aerospace Engineering
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                    
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Architect.docx" class="list-factsheet" download target="_blank">
                                        Architect
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Bioinformatician.docx" class="list-factsheet" download target="_blank">
                                        Bioinformatician
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Biomedical Engineer.docx" class="list-factsheet" download target="_blank">
                                        Biomedical Engineer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                    
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Business Operations Manager.docx" class="list-factsheet" download target="_blank">
                                        Business Operations Manager
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Content Creator.docx" class="list-factsheet" download target="_blank">
                                        Content Creator
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Copy of Data Scientist.docx" class="list-factsheet" download target="_blank">
                                        Copy of Data Scientist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a> 
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Copywriter.docx" class="list-factsheet" download target="_blank">
                                        Copywriter
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Data Scientist.docx" class="list-factsheet" download target="_blank">
                                        Data Scientist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Dentist_.docx" class="list-factsheet" download target="_blank">
                                        Dentist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Doctor.docx" class="list-factsheet" download target="_blank">
                                        Doctor
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a> 
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Energy Engineer.docx" class="list-factsheet" download target="_blank">
                                        Energy Engineer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Entrepreneur.docx" class="list-factsheet" download target="_blank">
                                        Entrepreneur
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Environmental Engineering.docx" class="list-factsheet" download target="_blank">
                                        Environmental Engineering
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Epidemiologist.docx" class="list-factsheet" download target="_blank">
                                        Epidemiologist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Financial Analyst.docx" class="list-factsheet" download target="_blank">
                                        Financial Analyst
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Food Scientist.docx" class="list-factsheet" download target="_blank">
                                        Food Scientist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Full Stack Developer.docx" class="list-factsheet" download target="_blank">
                                        Full Stack Developer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Genetic Counselor.docx" class="list-factsheet" download target="_blank">
                                        Genetic Counselor
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-left my-md-1">
                        <p style="font-size: 1.3em">
                            <ul style="padding-left: 0;">
                                <li>
                                    <a href="/file/resources-factsheet/Interior Designer.docx" class="list-factsheet" download target="_blank">
                                        Interior Designer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Investment Analyst.docx" class="list-factsheet" download target="_blank">
                                        Investment Analyst
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/IT Security.docx" class="list-factsheet" download target="_blank">
                                        IT Security
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Management Analyst.docx" class="list-factsheet" download target="_blank">
                                        Management Analyst
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Market Research Analyst.docx" class="list-factsheet" download target="_blank">
                                        Market Research Analyst
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Market Research Analyst.docx" class="list-factsheet" download target="_blank">
                                        Materials Engineering
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Multimedia Artist _ Animator.docx" class="list-factsheet" download target="_blank">
                                        Multimedia Artist Animator
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Pharmacists.docx" class="list-factsheet" download target="_blank">
                                        Pharmacists
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Process Control Engineer.docx" class="list-factsheet" download target="_blank">
                                        Process Control Engineer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Product Designer.docx" class="list-factsheet" download target="_blank">
                                        Product Designer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Psychologist.docx" class="list-factsheet" download target="_blank">
                                        Psychologist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Public Relation.docx" class="list-factsheet" download target="_blank">
                                        Public Relation
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Quality Assurance Tester.docx" class="list-factsheet" download target="_blank">
                                        Quality Assurance Tester
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Speech Pathologist.docx" class="list-factsheet" download target="_blank">
                                        Speech Pathologist
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Surgeon.docx" class="list-factsheet" download target="_blank">
                                        Surgeon
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/System Analyst.docx" class="list-factsheet" download target="_blank">
                                        System Analyst
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/UI_UX Designer.docx" class="list-factsheet" download target="_blank">
                                        UI/UX Designer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Veterinarian.docx" class="list-factsheet" download target="_blank">
                                        Veterinarian
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Video Game Designer.docx" class="list-factsheet" download target="_blank">
                                        Video Game Designer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="/file/resources-factsheet/Visual Designer_Graphic Designer.docx" class="list-factsheet" download target="_blank">
                                        Visual Designer/Graphic Designer
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-left my-md-1">
                        <p style="font-size: 1.3em">
                            <!-- <ul style="padding-left: 0;">
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="" class="list-factsheet" download target="_blank">
                                        
                                        <vue-feather type="download" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                            </ul> -->
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueFeather from "vue-feather";
export default {
  name: "CareerModule",
  components: {
    VueFeather,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.list-factsheet{
    color: #2c3e50;
    text-decoration: none;
    font-size: 18px;
}
</style>