<template>
    <div id="YoutubePlaylist">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="text-left my-md-1">
                        <p style="font-size: 1.3em">
                            <ul style="padding-left: 0;">
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JtmapTF8ChRtUx055X_0HWJ&jct=MPyv9CEQXUse2zbOQmqGOIWU-7bW6g" class="list-factsheet" download target="_blank">
                                        Accountant
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a> 
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju_a-CAejMSTmVdCbsSmM_g&jct=GDhufzlV954owRp-BazJi1fN-qFGdw" class="list-factsheet" download target="_blank">
                                        Actuary
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JtTsM1X6Ezwwr8JgNnvyEme&jct=DmgYVBx_2s9J-fA2UgTY9yOPe9SmcA" class="list-factsheet" download target="_blank">
                                        Aerospace Engineer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JtpBj9ISmqkmOrHrDAXLO1R&jct=tpumsbBFXOTlU47MJamQfE91u5r7wg" class="list-factsheet" download target="_blank">
                                        AI and Machine Learning Specialist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jt3UniQIJXrdzHSLVfsXDQJ&jct=cl6KMJeseW9yTJEJ89m-fBlQXYNS7g" class="list-factsheet" download target="_blank">
                                        Architect
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JtXYSMtvnC0EREZ15c-VVuy&jct=q-NML8rGKZDN4qCjzdZCtp7d2fcEDQ" class="list-factsheet" download target="_blank">
                                        Bioinformatician
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JvNWtcC2PwSbJ2QRlNrJlti&jct=3L7MZ0l8q0_rDDcCLULblxKzc9cnbA" class="list-factsheet" download target="_blank">
                                        Biomedical Engineer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Js2WvLmTsi4PjaE3ZPgGkD2&jct=6JyzZKKN2aiPsBTTY8fZe9VRpFfy3Q" class="list-factsheet" download target="_blank">
                                        Business Operations Manager
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a> 
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JubNgZ98HtDI_EOyVvHYprq&jct=-qGq7xjZ0x7XTKOAbs9jrFqKwzhekQ" class="list-factsheet" download target="_blank">
                                        Content Creator
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jts4vr1_bPO3FCS5tC25Ffu&jct=o5T8dIWv2cpi0XiZbGIvDPulX5rnsQ" class="list-factsheet" download target="_blank">
                                        Copywriter
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JurayN61n0P79HejUXHGzYC&jct=qhJj9K5qywwBKSX0zxMyGfo1EnyASQ" class="list-factsheet" download target="_blank">
                                        Data Scientist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JtF62ADob4TgtbU5sFi54sz&jct=4SvbPfHl2yZAG_8Do8MluqafzKAv_Q" class="list-factsheet" download target="_blank">
                                        Dentist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a> 
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju9um77XAiUnvqU5yp3ziIL&jct=qFA4AGlIormYCpsUww4cmGLJDvpFIw" class="list-factsheet" download target="_blank">
                                        Digital Marketing Specialist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju_AL23KAuBjxxtM67bAknW&jct=thHj3zv73yYLSjyWgX88WTBSW7Dvhg" class="list-factsheet" download target="_blank">
                                        Energy Engineer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsAxLwt6Pibg3sg2GcyHtJa&jct=OjMtcMDTKx8zT7dGJ65y4-6nsxV4EQ" class="list-factsheet" download target="_blank">
                                        Entrepreneur 
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jv48QXSjJzenVZ8ojPmVANA&jct=iDsO3srawPX03FXKzM7EoJV3mFZ5TQ" class="list-factsheet" download target="_blank">
                                        Environmental Engineering 
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Js20phIF00K05Xo62WTOof6&jct=kqJSOejEuimM8l19-qgw0ja5rxU2jQ" class="list-factsheet" download target="_blank">
                                        Epidemiologist 
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jv7Aq3LEBskXWDKcSQcliio&jct=TcvPww3FrsJ9-lDI7vQvus025ispkw" class="list-factsheet" download target="_blank">
                                        Event Planner
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jsed4zjp-vnAg8HKCgK1B3m&jct=TP4VPgPl8Szn_DgHf31Vl8MFjNwQYA" class="list-factsheet" download target="_blank">
                                        Financial Analyst
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsQbzDvw10ppwPAMbhnuTEh&jct=CyyVDC8S-i0u7v2CvrzFz3IyTVOXuA" class="list-factsheet" download target="_blank">
                                        Food Scientist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-left my-md-1">
                        <p style="font-size: 1.3em">
                            <ul style="padding-left: 0;">
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju9vKyu4N0Gcn5HaYFGtVm7&jct=i7rzYrHkCnJqtyg1ZOLTLN-sDavokw" class="list-factsheet" download target="_blank">
                                        Full Stack Engineer 
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jsg5PnjYnNeeuG2_ZtGsup2&jct=KksPw1U39Mhv_zhDzN3LbINJn1CK4g" class="list-factsheet" download target="_blank">
                                        Genetic Counselors
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jt9hTsLvbh0A_6VekdNYreM&jct=aOp_paRXVehCGg6Np6jwv3ha55nQ2Q" class="list-factsheet" download target="_blank">
                                        Interior Designer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsqNF23n4f4czBDrr4mXVy6&jct=4I0Y5KS70f1T4kUJDgir-vnc5lvMLQ" class="list-factsheet" download target="_blank">
                                        Internet of Things Developer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsYVu-w74CCocqudVlSfIwB&jct=QtWkpvKj8eDJmLxwmL2bBL7ksz64IQ" class="list-factsheet" download target="_blank">
                                        Investment Analyst
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Js9WUZ7438Q1a40A0NIJu7A&jct=upA6-QWPh7QpNeq0dOm1yY4Notx4OQ" class="list-factsheet" download target="_blank">
                                        IT Security
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsmsclYs1262gOkDVjl5ZA9&jct=3FuvtcPf4sPvq_esQdjiAxFyr32duw" class="list-factsheet" download target="_blank">
                                        Lawyer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JvB2wQXpZwBGx-yjPFAy9mn&jct=LlwBB60PGKA0XhJTcajuwom691E14Q" class="list-factsheet" download target="_blank">
                                        Management Analyst/Consultant
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JuA-P-hr-Qsv3tBv5XRDHvU&jct=9uYEZMw9In8XpdQ05eZ9ed4C5BR5eA" class="list-factsheet" download target="_blank">
                                        Market Research Analyst
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsF3IGS6rwe8NBGnYupC-7x&jct=7OOJf_uZKAmY57s5DbmOCMMNTAsHPw" class="list-factsheet" download target="_blank">
                                        Materials Engineer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jsvp5n3CR2ttVFhBjqNaxh7&jct=Q73RBaYPKCUT3ENWr-2WGL22iCrBvw" class="list-factsheet" download target="_blank">
                                        Medical Doctor
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JuDJ78G0DWSaYbZUTKfZYSE&jct=26T33oZlfvi3pdC3uMu0iljzDlaj8w" class="list-factsheet" download target="_blank">
                                        Multimedia Artist &amp; Animator
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JuEzY3l20rlzq0T5Ov8C5_6&jct=r9zIncz4fm7qYeKQESmx3_vCRFnk5w" class="list-factsheet" download target="_blank">
                                        Pharmacist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JtN3NZBcDIkHEwg41AB2l6u&jct=AUo-ZzRrGuDRNW__TZxpDKk4GlkRwA" class="list-factsheet" download target="_blank">
                                        Politician
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Juj0J7XeNd4uSfO7lxF_9PS&jct=9BFCqo30jOVw_OQM6sdIYOOMYoKuuQ" class="list-factsheet" download target="_blank">
                                        Process Control Engineer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Js9ZvPDFD5vWMx7YvKm45bT&jct=ywMZrYShs5GZbBkN0HVCQ-q3gG0qgw" class="list-factsheet" download target="_blank">
                                        Product Designer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsdeqgZGEdwWzfpfCwJlgOo&jct=exK5Qxantq4zcoVqPKb6Dgma433zQg" class="list-factsheet" download target="_blank">
                                        Psychologist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jsbx7hZTwYs8o50tVztFo7h&jct=eaWtQbL6PdlOIfpsZi595Fz89ij2OQ" class="list-factsheet" download target="_blank">
                                        Public Relations
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsWxbuesgNnJZVzM0RYB0CJ&jct=pDSM0lS8uCkYQ8VKLengDKS7wJE82g" class="list-factsheet" download target="_blank">
                                        QA Tester
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jvao7JU-DARueumsk6uEIZ8&jct=z2gDY3kFXvnpAeU1-xakGm2yzM-NlA" class="list-factsheet" download target="_blank">
                                        Software Engineer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-left my-md-1">
                        <p style="font-size: 1.3em">
                            <ul style="padding-left: 0;">
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju4q3bsp3XgNZtloqL3ahLs&jct=HoWiPp-dPasgbPZFTm1u2WodHTmvVg" class="list-factsheet" download target="_blank">
                                        Speech Pathologist
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JuwRTl-aK-cOFI-x0bz192k&jct=zqmPK1PCl713LRFUwGjuRRAi9SjWfw" class="list-factsheet" download target="_blank">
                                        Surgeon
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jt9bDUn0P84PHTC5VCCq84l&jct=XS_7eefysxG6-dqzPgrsq2zPPaWSJg" class="list-factsheet" download target="_blank">
                                        System Analyst
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JvZoLdHsx8sO-p5WtIeLyGi&jct=Xt3pTXjgwVRS6pLwIS5QH5bBY2WVtA" class="list-factsheet" download target="_blank">
                                        Urban and Regional Planner
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju6XFre_YtvaqDDAwEvR7ON&jct=c3UkiTOI3HY2CEB95PNTIo4bz07FTA" class="list-factsheet" download target="_blank">
                                        UX Designer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Ju8RpYQnd8l2czpZknehx49&jct=1d_b0wJhL-MMihq9ULSab2QktafuwA" class="list-factsheet" download target="_blank">
                                        Veterinarian
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsInfV1YSS8TKpVU1fg7UaE&jct=zznwuYwblF_HkZt7UGK0Y68GZoia0g" class="list-factsheet" download target="_blank">
                                        Video Game Designer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4Jt4vQQHV8JQop14ENHI0nFp&jct=k6bbkmZyNdYqP3nmwrv6eFJdrDsxcQ" class="list-factsheet" download target="_blank">
                                        Visual Designer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/playlist?list=PLDAyXldGL4JsUHjX69p1PsaqN12WJA0E3&jct=eA8t__jZXGX2N5KL_KFYVPupBoOyOw" class="list-factsheet" download target="_blank">
                                        Web Developer
                                        <vue-feather type="youtube" size="18" class="float-right"> </vue-feather>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueFeather from "vue-feather";
export default {
  name: "YoutubePlaylist",
  components: {
    VueFeather,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.list-factsheet{
    color: #2c3e50;
    text-decoration: none;
    font-size: 18px;
}
</style>